<template>
  <div>
    <div class="row">
      <div v-can="'access_eWallet'" class="col-xl-3 col-xxl-3 col-sm-6">
        <a href="javascript:void()" data-toggle="modal" data-target="#purchase">
          <div class="widget-stat card bg-white">
            <div class="card-body red-border-right">
              <div class="media">
                <span class="mr-3">
                  <i class="la la-gear"></i>
                </span>
                <div class="media-body text-white">
                  <h3 class="text-blue">Purchase</h3>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>

    <!-- purchase -->
    <div
      v-can="'access_eWallet'"
      class="modal fade"
      id="purchase"
      tabindex="-1"
      role="dialog"
      aria-labelledby="purchaseLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="purchaseLabel">Purchase</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form action="">
              <div class="form-group row">
                <label class="col-md-3 col-form-label">Student</label>
                <div class="col-md-9">
                  <TypeaheadInput
                    v-model="student_id"
                    :items="lists.students"
                    itemText="student_name"
                    itemValue="id"
                    placeholder="Choose a student"
                  >
                    <template v-slot:option-item="{ item }">
                      <!-- <span>{{ item.code }}</span> -->
                      <span>{{ item.student_name }}</span>
                    </template>
                  </TypeaheadInput>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-danger"
              id="close"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              :disabled="disableBtn"
              class="btn btn-primary"
              @click.prevent="proceed()"
            >
              Proceed
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TypeaheadInput from "vue3-typeahead-input";
import "vue3-typeahead-input/dist/style.css"; //Optional default CSS
import useStudentFinancialTransactions from "@/composables/student_financial_transactions";
import { onBeforeMount, ref, inject } from "vue";
import { useRouter } from "vue-router";

export default {
  components: {
    TypeaheadInput,
  },
  setup() {
    const hasPermission = inject("hasPermission");
    const disableBtn = ref(false);
    const student_id = ref(null);
    const router = useRouter();
    const { lists, getStudentsList } = useStudentFinancialTransactions();

    onBeforeMount(async () => {
      await getStudentsList();
    });

    const proceed = () => {
      if (hasPermission("access_eWallet")) {
        disableBtn.value = true;
        document.getElementById("close").click();
        router.push({
          name: "eWallet-Student-Purchase",
          params: { student_id: student_id.value },
        });
      } else {
        alert("You are not permitted!");
      }
    };

    return {
      lists,
      proceed,
      student_id,
      disableBtn,
    };
  },
};
</script>
